export const visitorExperiments = {
  Are_Green_Buttons_Better: ['on', 'off'],
  Tosse_Test_Visitors: ['on', 'off'],
  Test_That_Doesnt_Exist_Visitors: ['on', 'off'],
  Monthly_Billing: ['on', 'off'],
  OG_Nav_Enterprise: ['v1', 'v2'],
  PUG_Plans_Page_VDL: ['v1', 'off'],
} as const;

export const userExperiments = {
  Are_Red_Buttons_Better: ['on', 'off'],
  Tosse_Test_Users: ['on', 'off'],
  Test_That_Doesnt_Exist_Users: ['on', 'off'],
  PAY_License_Selector: ['on', 'off'],
  PAY_Quote_Button: ['on', 'off'],
} as const;

export type ContentVisitorExperiments = typeof visitorExperiments;
export type ContentUserExperiments = typeof userExperiments;
