var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e3a67a4d72f4b05a28508e6ae7c2e4a32ad8ca47"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {
  standardSentryConfig,
  extraClientConfig,
} from '@mentimeter/sentry-config';

const environmentName = globalThis.__mentimeterEnv['NEXT_PUBLIC_ENVIRONMENT_NAME'] ?? 'dev';
const shouldHavePerformanceMonitor = environmentName === 'prod';

Sentry.init({
  dsn:
    globalThis.__mentimeterEnv['NEXT_PUBLIC_PAGES_ROUTER'] === 'true'
      ? 'https://6a977aec3cea4df6a9fdf2f869e16492@o866780.ingest.sentry.io/5930278'
      : 'https://0f6945da36d5073846961be1f39e1d78@o866780.ingest.us.sentry.io/4507186403540992',
  ...standardSentryConfig,
  ...extraClientConfig,
  // This MUST be here as overwrite in the client config for env to be set with next-env-vars
  // Can be removed when this application is cf pages only
  environment: environmentName,
  tracesSampleRate: shouldHavePerformanceMonitor ? 0.03 : 0,
  integrations: [Sentry.browserTracingIntegration()],
});
